import { useStaticQuery, graphql } from "gatsby";
import { mapContentPage } from "@src/helpers/craft";

const query = graphql`
  {
    craft {
        entry(section: "submitResearch") {
    ... on Craft_submitResearch_submitResearch_Entry {
      title
    body
      description
     }
  }
    }
  }
`;

export const useCraft = () => {
  const { craft } = useStaticQuery(query);
  return mapContentPage(craft);
};
